<template>
  <div>
    <h1>UI KIT</h1>

    <section class="section page__content">
      <h2 class="mb-m">Form</h2>

      <VForm v-model="formData">
        <div style="display: flex; justify-content: space-between; flex-wrap: wrap; width: 80%; margin-bottom: 30px">
          <VInput label="Disabled" value="sss" disabled width="20%" />
          <VInput label="Phone" name="phone" v-model="phone" maskRegex="+7 (###) ### ##-##"  width="20%" />
          <VInput label="Password" name="password" type="password" required width="20%" />
          <VInput label="Search" name="search" type="search" width="20%" />

          <VSelect
            label="Old rebase select"
            placeholder="Выберите вариант"
            name="oldSelect"
            required
            multiple
            item-text="name"
            :options="[
              { id: 1, name: 'Вариант 1' },
              { id: 2, name: 'Вариант 2' },
              { id: 3, name: 'Вариант 3' },
              { id: 4, name: 'Вариант 4' },
            ]"
          >
            <Validation for="required">Поле обязательно</Validation>
          </VSelect>

          <VInput
            name="required"
            required
            label="required"
            placeholder="required"
            width="48%"
          >
            <Validation for="required">Поле обязательно</Validation>
          </VInput>

          <VInput
            name="minmax"
            :minValue="3"
            :maxValue="10"
            label="Min & Max value for numbers"
            maskRegex="##"
            width="48%"
          >
            <Validation for="minValue">min value - 3</Validation>
            <Validation for="maxValue">max value - 10</Validation>
          </VInput>

          <VInput
            name="length"
            minLength="3"
            maxLength="5"
            :limitMaxLength="9"
            label="Min & Max length value"
            :maskRegex="/^\d+$/"
            width="48%"
          >
            <Validation for="minLength">min length - 3</Validation>
            <Validation for="maxLength">max length - 5</Validation>
          </VInput>

          <VInput
            name="pattern"
            :pattern="/^\d+$/"
            label="pattern"
            width="48%"
          >
            <Validation for="pattern">pattern - only numbers</Validation>
          </VInput>

          <VInput
            name="not"
            not="not"
            label="not"
            placeholder="not"
            width="48%"
          >
            <Validation for="not">Value === 'not'</Validation>
          </VInput>

          <VInput
            name="sameAs"
            sameAs="sameAs"
            label="sameAs"
            width="48%"
          >
            <Validation for="sameAs">Value !== 'sameAs'</Validation>
          </VInput>

          <VInput
            name="custom"
            :customValidation="val => val % 2 === 0"
            label="Custom Validation"
            width="48%"
          >
            <Validation for="customValidation">Только четные числа</Validation>
          </VInput>

          <VInput
            name="server"
            :server="promise"
            label="Server validate"
            width="48%"
            :loading="serverValidatorLoading"
            placeholder="length == 1 & send server valid (условие кастомное)"
          >
            <Validation for="server">серверная валидация не прошла</Validation>
          </VInput>

          <VInput
            name="email"
            email
            label="email"
            width="48%"
          >
            <Validation for="email">email is not valid</Validation>
          </VInput>
        </div>

        <div style="display: flex; flex-wrap: wrap; gap: 20px">
          <VButton
            text="Submit"
            type="submit"
            color="primary"
            size="xl"
            :icon="{ name: 'warn', width: '20', height: '20', fill: true }"
          />
          <VButton
            text="Disabled"
            color="primary"
            size="xl"
            loading
          />
          <VButton
            text="Кнопка в форме но не сабмит"
            size="xl"
            @click.native.prevent="() => alert('не вызывает сабмит формы')"
          />
          <VButton
            text="Disabled"
            color="primary"
            size="xl"
            icon="add"
            disabled
          />
          <VButton
            text="Submit"
            type="submit"
            color="green"
            icon="eye"
          />
          <VButton
            text="Submit"
            type="submit"
            color="red"
            size="xl"
            icon="eye"
          />
          <VButton
            color="red"
            size="init"
            icon="eye"
          />
          <VButton
            text="Disabled"
            icon="eye"
            disabled
          />
        </div>
      </VForm>
    </section>

    <section class="mb-xl">
      <h2 class="mb-m">Modal</h2>

      <div class="buttons">
        <VButton
          text="Удалить элемент"
          size="sm"
          icon="remove"
          @click.native="openModal('confirm')"
        />

        <VButton
          text="Вызвать modal"
          size="sm"
          color="primary-sm"
          icon="create"
          @click.native="openModal('create')"
        />
      </div>

      <transition name="fade">
        <ModalConfirmation
          v-if="isModal === 'confirm'"
          @confirm="closeModal"
          @reject="closeModal"
        />

        <ModalForm
          v-if="isModal === 'create'"
          title="Вы точно хотите покинуть приложение?"
          class="modal--title-reset"
          text-btn-confirm="Выход"
          @request="closeModal"
          @reject="closeModal"
        />
      </transition>
    </section>

    <section class="mb-xl">
      <h2 class="mb-m">Link</h2>

      <div class="flex gap-s">
        <a href="#" class="link">Link</a>

        <a href="#" class="link link_dotted">Link dotted</a>
      </div>
    </section>

    <section class="mb-xl">
      <h2 class="mb-s">Tooltip</h2>

      <div class="flex gap-m">
        <VTooltip text="Текст подсказки" has-icon show-tip />

        <VTooltip text="Текст подсказки" tooltip-side="right" has-icon show-tip />
      </div>
    </section>

    <section class="mb-xl">
      <h2 class="mb-30">Alert</h2>

      <div class="d-grid d-grid--columns-2 gap-s max-w-form-grid--col-2">
        <AlertCard
          v-for="(item, i) of alerts"
          :key="i"
          :alert="item"
        />
      </div>
    </section>

    <section class="mb-xl">
      <h2 class="mb-m">Button</h2>

      <div class="flex gap-m mb-s">
        <VButton
          text="Кнопка"
          color="primary"
          size="xl"
        />

        <VButton
          text="Кнопка"
          color="primary"
          size="xl"
          disabled
        />
      </div>

      <div class="flex gap-m mb-s">
        <VButton
          text="Кнопка"
          size="xl"
        />

        <VButton
          text="Кнопка"
          color="green"
          size="xl"
        />

        <VButton
          text="Кнопка"
          color="orange"
          size="xl"
        />

        <VButton
          text="Кнопка"
          color="red"
          size="xl"
        />

        <VButton
          text="Кнопка"
          size="xl"
          disabled
        />
      </div>

      <div class="buttons gap-m">
        <VButton
          text="Создать"
          size="sm"
          color="primary-sm"
          icon="create"
        />

        <VButton
          text="Добавить"
          size="sm"
          icon="add"
        />

        <VButton
          text="Редактировать"
          size="sm"
          icon="edit"
        />

        <VButton
          text="Удалить"
          size="sm"
          icon="remove"
        />

        <VButton
          text="Экспортировать"
          size="sm"
          icon="export"
        />

        <VButton
          text="На печать"
          size="sm"
          icon="print"
        />
      </div>
    </section>

    <section class="mb-xl">
      <h2 class="mb-m">Input</h2>

      <div class="d-grid d-grid--columns-2 gap-m max-w-form-grid--col-2 mb-s">
        <VInput label="Label:" />

        <VInput
          label="Password:"
          type="password"
        />

        <VInput
          label="Error:"
          :customValidation="() => false"
        >
          <Validation for="customValidation">текст ошибки</Validation>
        </VInput>

        <VInput label="Input disabled:" disabled />

        <VInput label="Search" type="search"/>
      </div>

      <div class="d-grid d-grid--columns-2 gap-m max-w-form-grid--col-2 mb-s">
        <VInput
          type="textarea"
          label="Textarea:"
        />

        <VInput
          type="textarea"
          label="Textarea disabled:"
          disabled
        />

        <VInputDate label="Date Range" type="range"/>

        <VInputDate label="Date Single Timer" type="single" timer />
      </div>
    </section>

    <section>
      <h2 class="mb-m">Checkbox</h2>

      <div class="d-grid d-grid--columns-2 gap-xs max-w-input">
        <VCheckbox
          v-model="checkBoxTest.checkboxActive"
          label="Checkbox"
        />

        <VCheckbox
          label="Checkbox disabled"
          is-disabled
        />

        <VCheckbox
          v-model="checkBoxTest.radioActive"
          label="Radio"
          type="radio"
        />

        <VCheckbox
          label="Radio disabled"
          type="radio"
          is-disabled
        />

        <VCheckbox
          v-model="checkBoxTest.switchActive"
          label="Switch"
          type="switch"
        />

        <VCheckbox
          label="Switch disabled"
          type="switch"
          is-disabled
        />
      </div>
    </section>
  </div>
</template>

<script>
import AlertCard from '@/components/Alert/AlertCard'
import VInputDate from '@/components/Form/VInputDate'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import VForm from '@/components/Form/VForm'
import VCheckbox from '@/components/Form/VCheckbox'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'
import VSelect from '@/components/Form/VSelect'
import VButton from '@/components/simple/button/VButton'
import ModalForm from '@/components/ui/Modal/ModalForm'
import VTooltip from '@/components/ui/VTooltip'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { setAlertError } from '@/utils/store/alert'
import { ALERT_TITLE } from '@/const/alert'
import { generateLorem } from '@/mocks/mockLorem'

export default {
  name: 'ui-kit',
  components: {
    AlertCard,
    VTooltip,
    ModalForm,
    ModalConfirmation,
    VInputDate,
    VForm,
    VCheckbox,
    VInput,
    VSelect,
    Validation,
    VButton
  },
  mixins: [mixinModal],

  data () {
    return {
      formData: {
        minmax: '0',
        length: '12',
        not: 'not',
        pattern: 'буквы',
        sameAs: 'not sameAs',
        custom: '3',
        email: 'неправильный email.ru'
      },
      phone: '999999',
      serverValidatorLoading: false,

      checkBoxTest: {
        checkboxActive: false,
        radioActive: false,
        switchActive: false
      },

      alerts: Object.keys(ALERT_TITLE).map(item => ({
        type: item,
        message: generateLorem(20)
      }))
    }
  },

  methods: {
    alert (text) {
      alert(text)
    },
    async promise (val) {
      if (val.length === 1) {
        const prom = () => {
          return new Promise((resolve) => setTimeout(resolve, 3000))
        }
        this.serverValidatorLoading = true
        await prom()
        this.serverValidatorLoading = false
        await setAlertError('', val + ' не прошло серверную валидацию')
        return false
      }

      return true
    }
  }
}
</script>
