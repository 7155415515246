<template>
  <BaseModal
    :title="title"
    @close="onReject"
  >
    <slot />

    <ButtonGroup
      :textBtnConfirm="textBtnConfirm"
      :loading="$store.state.isSendingRequestModal"
      is-prevent
      @confirm="onConfirm"

      :textBtnReject="textBtnReject"
      @reject="onReject"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'

import { mixinButtonGroup } from '@/mixins/button/mixinButtonGroup'

export default {
  name: 'ModalConfirmation',
  components: {
    BaseModal,
    ButtonGroup
  },
  mixins: [mixinButtonGroup],
  props: {
    title: {
      type: String,
      required: false,
      default: 'Удалить выбранный элемент?'
    }
  }
}
</script>
