const mixinModal = {
  data: () => ({
    modal: {
      isModal: false
    }
  }),
  computed: {
    isModal () {
      return this.modal.isModal
    }
  },
  methods: {
    openModal (modalName = true) {
      this.modal.isModal = modalName
    },

    closeModal () {
      this.modal.isModal = false
    }
  }
}

export {
  mixinModal
}
